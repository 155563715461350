import React from "react";

export default function DaggerSymbol() {
    // Prevent mobile devices from converting the symbol into an emoji.
    return <>&#x2020;&#xFE0E;</>;
}

export function DoubleDaggerSymbol() {
    return <>&#x2021;&#xFE0E;</>;
}
