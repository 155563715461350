import React, {useEffect, useRef} from 'react';
import FocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import Portal from '../portal/Portal';
import * as SS from '@techstack/styled-system';

interface PanelProps extends SS.LayoutProps {
    ariaLabel: string
}

const Panel = styled.div.attrs<PanelProps>((props: PanelProps) => ({
    "aria-label": props.ariaLabel
})) <PanelProps>`
    position: absolute;
    top: 0;
    right: 0;
    ${SS.space};
    ${SS.layout};
    height: 100%;
    box-shadow: 0 0px 8px 0px #555555;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: white;
    z-index: 1;
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 20000000;

    .oui-drawer__overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100%;
    }

    .oui-drawer__overlay {
        overflow: hidden;
        background: rgba(0, 0, 0, 0.5);
        z-index: 0;
    }

    &.slide-enter {
        opacity: 0;
    }

    &.slide-enter .oui-drawer__panel {
        transform: translateX(320px);
    }

    &.slide-enter-active {
        opacity: 1;
        transition: opacity 350ms;
    }

    &.slide-enter-active .oui-drawer__panel {
        transform: translateX(0);
        transition: transform 200ms;
    }

    &.slide-exit {
        opacity: 1;
    }

    &.slide-exit .oui-drawer__panel {
        transform: translateX(0);
    }

    &.slide-exit-active {
        opacity: 0;
        transition: opacity 200ms;
    }

    &.slide-exit-active .oui-drawer__panel {
        transform: translateX(320px);
        transition: transform 200ms;
    }
`;

interface Props extends SS.LayoutProps, SS.SpaceProps {
    isOpen: boolean;
    onOverlayClick: Function;
    children: any;
    ariaLabel: string;
}

export function Drawer({ isOpen, onOverlayClick, width = 340, children, ariaLabel }: Props) {
    const nodeRef = useRef(null);

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <Portal>
            <CSSTransition nodeRef={nodeRef} in={isOpen} timeout={350} classNames="slide" mountOnEnter={true} unmountOnExit={true}>
                <Container ref={nodeRef}>
                    <div className="oui-drawer__overlay" onClick={() => onOverlayClick()} />
                    <Panel className="oui-drawer__panel" width={width} role="dialog" ariaLabel={ariaLabel}>
                        <FocusLock lockProps={{
                            style: {
                                height: "100%"
                            }
                        }}>
                            {children}
                        </FocusLock>
                    </Panel>
                </Container>
            </CSSTransition>
        </Portal>
    );
}

export default Drawer;
