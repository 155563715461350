import React from "react";
import Text from "@onnit-js/ui/components/text";
import Box from "@onnit-js/ui/components/box/Box";
import CheckCircleIcon from "@onnit-js/ui/components/icon/CheckCircleIcon";

const AddedMessage = () => (
    <Box display="flex" alignItems="center" p={5}>
        <CheckCircleIcon checked mr={3} />
        <Text color="sunwashGold" fontWeight="heavy">
            Added to your bag
        </Text>
    </Box>
);

export default AddedMessage;
